import React, { useState } from "react";
import { BannerJobs } from "./BannerJobs/BannerJobs";
import { OurCulture } from "./OurCulture/OurCulture";
import { Vacancies } from "./Vacancies/Vacancies";
import { VacancyList } from "../SectionWhyWorkAtLiftit/components/VacancyList/VacancyList";
import { SectionWhyWorkAtLiftit } from "../SectionWhyWorkAtLiftit/SectionWhyWorkAtLiftit";


import planet_animated from "../../../assets/images/jobs/planet_animated.png";
import planet_1 from "../../../assets/images/jobs/planet_1.png";
import planet_2 from "../../../assets/images/jobs/planet_2.png";
import planet_3 from "../../../assets/images/jobs/planet_3.png";

export const SectionBanner = ({ sectionInitial, dummyCity, dummyBusiness, dummyCardsSearch, subTitle, text_2 }) => {

	const [sectionInternal, setSectionInternal] = useState("");

	return (
		<div className="SectionBanner">
			<div>
				<div className={`planet--animated animate__fadeInRight animate__animated 
					${sectionInternal === "SectionWhyWorkAtLiftit" && "animated-planets"}
					${sectionInternal === "OurCulture" && "animated-planets--phase-three"}
					${sectionInternal === "Vacancies" && "animated-planets--phase-four"}
					${sectionInternal === "VacancyList" && "animated-planets--phase-five"}
					${sectionInternal === "VacancyList-to-our" && "animated-planets--phase-six"}
					`}>
					<div>
						<img src={planet_1} className="planet_small planet-one" />
						{sectionInitial === "banner" &&
							<>
								<img src={planet_2} className="planet_small planet-two" />
								<img src={planet_3} className="planet_small planet-three" />
							</>
						}
					</div>
					<div>
						<img src={planet_animated} className={`big-planet animated-big-planet-sectionWork`} />
					</div>
				</div>
			</div>

			{sectionInitial === "banner" && sectionInternal === "" &&
				<BannerJobs handlerSetSectionInternal={setSectionInternal}/>
			}
			{sectionInitial === "footer" && sectionInternal === "" &&
				<SectionWhyWorkAtLiftit
					dummyCity={dummyCity}
					handlerSetSectionInternal={setSectionInternal}
					title="¿Por qué trabajar en Liftit?"
					text_1="Nuestra cultura se basa en mantener un ritmo de productividad sostenible y una vida plena fuera del trabajo. Maximizamos nuestra capacidad para asumir grandes proyectos, creando un equipo diverso que combina su experiencia y capacidades para afrontar los desafíos del día a día. Estamos orgullosos de nuestro trabajo y de nuestro equipo, y estamos seguros de la importancia de contar con los mejores, por eso nos esforzamos en mantener un ambiente laboral sano y brindar bienestar a todos nuestros colaboradores."
					subTitle="Vacantes"
					text_2="¿Tienes una ‘actitud de otro planeta’? Eres talentoso/a, buscas aprender y crecer constantemente, ¡Te estamos buscando! Ven y forma parte del equipo mundial de aliens que está revolucionando la logística y moviendo a Latinoamérica."
					dummyBusiness={dummyBusiness}
					dummyCardsSearch={dummyCardsSearch}
				/>
			}
			{sectionInternal === "SectionWhyWorkAtLiftit" &&
				<SectionWhyWorkAtLiftit
					dummyCity={dummyCity}
					sectionInitial={sectionInitial}
					handlerSetSectionInternal={setSectionInternal}
					title="¿Por qué trabajar en Liftit?"
					text_1="Nuestra cultura se basa en mantener un ritmo de productividad sostenible y una vida plena fuera del trabajo. Maximizamos nuestra capacidad para asumir grandes proyectos, creando un equipo diverso que combina su experiencia y capacidades para afrontar los desafíos del día a día. Estamos orgullosos de nuestro trabajo y de nuestro equipo, y estamos seguros de la importancia de contar con los mejores, por eso nos esforzamos en mantener un ambiente laboral sano y brindar bienestar a todos nuestros colaboradores."
					subTitle="Vacantes"
					text_2="¿Tienes una ‘actitud de otro planeta’? Eres talentoso/a, buscas aprender y crecer constantemente, ¡Te estamos buscando! Ven y forma parte del equipo mundial de aliens que está revolucionando la logística y moviendo a Latinoamérica."
					dummyBusiness={dummyBusiness}
					dummyCardsSearch={dummyCardsSearch}
				/>
			}
			{sectionInternal === "OurCulture" &&
				<OurCulture handlerSetSectionInternal={setSectionInternal} sectionInitial={sectionInitial}/>
			}
			{sectionInternal === "Vacancies" &&
				<Vacancies
					dummyCity={dummyCity}
					dummyBusiness={dummyBusiness}
					handlerSetSectionInternal={setSectionInternal}
				/>
			}
			{sectionInternal === "VacancyList" &&
				<VacancyList
					subTitle={subTitle}
					handlerSetSectionInternal={setSectionInternal}
					text_2={text_2}
					dummyCardsSearch={dummyCardsSearch}
				/>
			}
			{sectionInternal === "VacancyList-to-our" &&
				<VacancyList
					subTitle={subTitle}
					handlerSetSectionInternal={setSectionInternal}
					text_2={text_2}
					dummyCardsSearch={dummyCardsSearch}
				/>
			}
		</div>
	);
}