import React from "react"
import { Button } from "antd"

export const CardInformation = ({ dummyCardsSearch }) => {
  return (
    <div className="card-information">
      {dummyCardsSearch?.map((item, index) => (
        <div className="card" key={index}>
          <div className="card__body">
            <div className="circle" />
            <div className="information">
              <h2>{item.title}</h2>
              <h4>{item.date}</h4>
              <h4>{item.description}</h4>
            </div>
          </div>
          <div className="card__footer">
            <div className="left">
              <h4>{item.remote}</h4>
              <h4>${item.value}</h4>
            </div>
            <div className="cont-btn">
              <Button className="btn btn--primary">Ver más</Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
