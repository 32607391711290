import React from "react"
import ReactPlayer from "react-player"
import { useTranslation } from "react-i18next"

export const SectionAboutUs = ({ title, text, video, link }) => {

  const { t } = useTranslation()

  return (
    <div className="SectionAboutUs">
      <div className="SectionAboutUs__container">
        <div className="side-left">
          <h1>{title}</h1>
          <p>{text}</p>
          <a href={link.link?.link} target="_blank">
            {t('button.read_more_here')}
          </a>
        </div>
        <div className="side-right">
          <div className="side-right__cont-video" data-aos="fade-up">
            <ReactPlayer
              url={video}
              className="video"
              playing={false}
              config={{
                youtube: {
                  playerVars: {
                    controls: 0,
                    fs: 0,
                    modestbranding: 0,
                  },
                },
              }}
            />
          </div>
          <div className="back" />
        </div>
      </div>
    </div>
  )
}
