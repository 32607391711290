import React from "react";
import Slider from "react-slick";
import { DownOutlined } from '@ant-design/icons';
import { Button } from "antd";

export const OurCulture = ({handlerSetSectionInternal , sectionInitial}) => {

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		className: "center",
		centerMode: true,
		centerPadding: "100px",
	};

	const handleSection = values => {
		handlerSetSectionInternal(values)
	}

	const dummyCardsCaroucel = [
		{ id: 1, name: "Lo que somos", text: "Liftit es una oportunidad, es un lugar donde puedes reconocerte a ti mismo, aprender, explorar y explotar tus habilidades. " },
		{ id: 2, name: "¿Por qué Liftit?", text: "Liftit es una oportunidad, es un lugar donde puedes reconocerte a ti mismo, aprender, explorar y explotar tus habilidades. " },
		{ id: 3, name: "Nuestra base", text: "Liftit es una oportunidad, es un lugar donde puedes reconocerte a ti mismo, aprender, explorar y explotar tus habilidades. " },
		{ id: 4, name: "Lo que somos", text: "Liftit es una oportunidad, es un lugar donde puedes reconocerte a ti mismo, aprender, explorar y explotar tus habilidades. " }
	]

	return (
		<div className={`vacancies-section ${sectionInitial === "banner" && "vacancies-section--back"}`}>
			<div className="vacancies-section__content">
				<h6 className="head">Jobs/Liftit</h6>
				<div className="caroucel--vacancies">
					<Slider {...settings}>
						{dummyCardsCaroucel?.map((item, index) =>
							<div key={index} className="item">
								<h3 className="title">{item.name}</h3>
								<p className="text">{item.text}</p>
							</div>
						)}
					</Slider>
				</div>
				<h5 className="foo">
					¡Movemos Latam!
        </h5>
				<div className="cont-next">
					<Button onClick={() => handleSection("VacancyList-to-our")} className="next">
						<h6>
							Lorem ipsum cua homt
						</h6>
						<DownOutlined />
					</Button>
				</div>
			</div>
		</div>
	);
}