import React, { useState, useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { Button } from "antd";

export const SectionWhyWorkAtLiftit = ({
  title,
  text_1,
  handlerSetSectionInternal,
  sectionInitial
}) => {
  const [showVacancies, setShowVacancies] = useState(false)
  const [sectionContinue, inSectionContinue] = useInView({ threshold: 0 })
  const [vacancyList, setVacancyList] = useState(false)

  useEffect(() => {
    if (inSectionContinue) setShowVacancies(true)
  }, [inSectionContinue])

  const handleVacancies = (values) => {
    handlerSetSectionInternal(values)
  }

  return (
    <div className={`SectionWhyWorkAtLiftit ${sectionInitial === "banner" && "SectionWhyWorkAtLiftit--back"}`}>
      {!vacancyList && (
        <div className={`SectionWhyWorkAtLiftit__container ${sectionInitial === "banner" && "border-none"}`}>
          <h6>Jobs/Liftit</h6>

          <div className="description" data-aos="fade-up">
            <h1>{title}</h1>
            <p>{text_1}</p>
            <div className="cont-btn">
              <Button onClick={()=>handleVacancies("Vacancies")} className="btn btn--primary">Ver vacantes</Button>
              <Button onClick={()=>handleVacancies("OurCulture")} className="btn btn--demo">Nuestra cultura</Button>
            </div>
          </div>
          <h5 ref={sectionContinue} className="foo">
            ¡Movemos Latam!
          </h5>
        </div>
      )}
    </div>
  )
}
