import React from "react"
import Slider from "react-slick"

import { RightOutlined } from "@ant-design/icons"

export const SectionMedia = ({ title, company, subTitle, caroucel }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  }

  return (
    <div className="SectionMedia">
      <div className="SectionMedia__container">
        <h1>{title}</h1>
        <div className="companies">
          {company?.map((item, index) => (
            <div key={index} className="company">
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="SectionMedia--content">
        <div className="SectionMedia--content--center">
          <h1>{subTitle}</h1>
          <div className="cont-caroucel">
            <Slider {...settings}>
              {caroucel?.map((item, index) => (
                <div className="cont-caroucel__item" key={index}>
                  <h3>{item.title}</h3>
                  <p>{item.text}</p>
                  <div className="footer">
                    <a>Lorem</a>
                    <a>Lorem</a>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="cards-res">
        {caroucel?.map((item, index) => (
          <div key={index} className="card">
            <h3>{item.title}</h3>
            <p>{item.text}</p>
            <div className="footer">
              <a>Lorem</a>
              <RightOutlined />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
