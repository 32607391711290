import React from "react"
import CountUp from "react-countup"
import { useInView } from "react-intersection-observer"

export const Count = ({ number, prefix, decimals }) => {
  const isInt = n => n % 1 === 0
  
  const [animatedSection, inAnimatedSection] = useInView({ threshold: 0 })

  return (
    <span ref={animatedSection}>
      {inAnimatedSection && (
        <CountUp
          prefix={prefix}
          start={0}
          duration={4}
          end={number}
          decimals={decimals}
          decimals={isInt(number) ? 0 : 1}
        />
      )}
    </span>
  )
}
