import React from "react";
import { Count } from "../../../PageJobs/SectionObjectives/components/Count"
import { Button } from "antd";

export const BannerJobs = ({ handlerSetSectionInternal }) => {

	const handleSection = values => {
		handlerSetSectionInternal(values)
	}


	return (
		<div className="banner-jobs">
			<div className="banner-jobs-center">
				<div className="text">
					<h1>Bienvenidos a la revolución logística en América Latina</h1>
					<p>
						En Liftit revolucionamos la forma de hacer logística y brindamos
						soluciones efectivas para la cadena de suministro por medio de
						herramientas tecnológicas, acompañados de un equipo experto,
						comprometido y talentoso. ¡Aquí, movemos a Latinoamérica!
							</p>
					<img
						src="https://res.cloudinary.com/arepa-tec/image/upload/v1599500911/lifters/backworld_sdse3u.png"
						className="world"
						alt="world"
					/>
					<div className="cont-btn">
						<Button onClick={() => handleSection("SectionWhyWorkAtLiftit")} className="btn btn--primary">
							Ver vacantes
								</Button>
					</div>
				</div>
				<div className="numbers">
					<div className="numbers--box">
						<h4>
							<Count number={1234} />
						</h4>
					</div>
					<div className="numbers--box">
						<div className="numbers--box--cont">
							<h4>
								<Count number={8623} />
							</h4>
							<span>H</span>
						</div>
						<div className="numbers--box--cont">
							<h4>
								<Count number={1234} />
							</h4>
							<span>V</span>
						</div>
						<div className="numbers--box--cont">
							<h4>
								<Count number={3560} />
							</h4>
							<span>Z</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}