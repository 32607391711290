import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import Layout from "../../components/Layout/LayoutPublic/LayoutPublic"
import avatar from "../../assets/images/avatarjobs.png"
import planet1 from "../../assets/images/planet1.png"
import planet2 from "../../assets/images/planet2.png"
import planet3 from "../../assets/images/planet3.png"
import planet4 from "../../assets/images/planet4.png"
import { SectionAboutUs } from "../../components/PageJobs/SectionAboutUs/SectionAboutUs"
import { SectionObjectives } from "../../components/PageJobs/SectionObjectives/SectionObjectives"
import { SectionMedia } from "../../components/PageJobs/SectionMedia/SectionMedia"
import { SectionFounders } from "../../components/PageJobs/SectionFounders/SectionFounders"
import { SectionWhyWorkAtLiftit } from "../../components/PageJobs/SectionWhyWorkAtLiftit/SectionWhyWorkAtLiftit"
import SEO from '../../components/seo'
import { SectionBanner } from "../../components/PageJobs/SectionBanner/SectionBanner"

const Jobs = ({ data: { contentfulPaginaJobs: contentfulPageJobs } }) => {

  const { languageISO639 } = useSelector(state => state.language)

  useEffect(() => {
     document.body.style.overflowX = "hidden"
  }, [])

  if (!contentfulPageJobs)
    return null

  const dummyCompany = [
    { id: 1, name: "Logo" },
    { id: 2, name: "Empresa" },
    { id: 3, name: "Compa" },
    { id: 4, name: "Otra" },
    { id: 5, name: "Equis" },
  ]

  const dummyCaroucelMedia = [
    {
      id: 1,
      title: "Pellentesque pellentesque mi justo, est ornare",
      text:
        "Nulla augue lacus, mi tellus tortor in leo. Interdum nisl, fermentum ac mattis porttitor. Rutrum massa aliquam sed libero cursus sed. Vehicula mauris,",
    },
    {
      id: 2,
      title: "Pellentesque pellentesque mi justo, est ornare",
      text:
        "Nulla augue lacus, mi tellus tortor in leo. Interdum nisl, fermentum ac mattis porttitor. Rutrum massa aliquam sed libero cursus sed. Vehicula mauris,",
    },
    {
      id: 3,
      title: "Pellentesque pellentesque mi justo, est ornare",
      text:
        "Nulla augue lacus, mi tellus tortor in leo. Interdum nisl, fermentum ac mattis porttitor. Rutrum massa aliquam sed libero cursus sed. Vehicula mauris,",
    },
    {
      id: 4,
      title: "Pellentesque pellentesque mi justo, est ornare",
      text:
        "Nulla augue lacus, mi tellus tortor in leo. Interdum nisl, fermentum ac mattis porttitor. Rutrum massa aliquam sed libero cursus sed. Vehicula mauris,",
    },
  ]

  const dummyFounders = [
    {
      id: 1,
      name: "Brian York",
      rol: "CEO y Co-founder",
      description:
        "“Emprender es compromiso. Estar disponible las 24 horas del día, 7 días a la semana”",
      image:
        "https://res.cloudinary.com/arepa-tec/image/upload/v1599065484/lifters/csa_1_uyrdwz.png",
    },
    {
      id: 2,
      name: "Felipe York",
      rol: "CEO y Co-founder",
      description:
        "“Emprender es compromiso. Estar disponible las 24 horas del día, 7 días a la semana”",
      image:
        "https://res.cloudinary.com/arepa-tec/image/upload/v1598569266/lifters/Untitled-1_1_3_-min_gbenzo.png",
    },
    {
      id: 3,
      name: "Brian York",
      rol: "CEO y Co-founder",
      description:
        "“Emprender es compromiso. Estar disponible las 24 horas del día, 7 días a la semana”",
      image:
        "https://res.cloudinary.com/arepa-tec/image/upload/v1599060328/lifters/image_17_m4lspq.png",
    },
  ]

  const dummyEmployees = [
    {
      id: 1,
      employees: [
        {
          id: 1,
          name: "Ronaldo Goméz",
          rol: "Lorem Ipsum",
          key: "employee-1",
          photo:
            "https://res.cloudinary.com/arepa-tec/image/upload/v1599060328/lifters/image_17_m4lspq.png",
          testimony:
            "Sodales felis viverra morbi tellus eu aliquam malesuada. Egestas in diam risus at et. Pulvinar phasellus ut pharetra donec pellentesque facilisi aliquam nam nisl. Metus purus feugiat egestas ac, sit eleifend massa. Varius cursus pellentesque amet posuere egestas urna odio risus lorem. Arcu sed proin mattis cras. Sed amet, elementum lectus bibendum mi. Arcu ligula nibh nam sed convallis rhoncus morbi.",
        },
        {
          id: 2,
          name: "Ronaldo Goméz",
          rol: "Lorem Ipsum",
          key: "employee-2",
          photo:
            "https://res.cloudinary.com/arepa-tec/image/upload/v1599060327/lifters/image_27_xcu5mi.png",
          testimony:
            "Sodales felis viverra morbi tellus eu aliquam malesuada. Egestas in diam risus at et. Pulvinar phasellus ut pharetra donec pellentesque facilisi aliquam nam nisl. Metus purus feugiat egestas ac, sit eleifend massa. Varius cursus pellentesque amet posuere egestas urna odio risus lorem. Arcu sed proin mattis cras. Sed amet, elementum lectus bibendum mi. Arcu ligula nibh nam sed convallis rhoncus morbi.",
        },
      ],
      title: "Tech leads",
    },
    {
      id: 2,
      employees: [
        {
          id: 3,
          name: "Ronaldo Goméz",
          rol: "Lorem Ipsum",
          key: "employee-3",
          photo:
            "https://res.cloudinary.com/arepa-tec/image/upload/v1599060328/lifters/image_21_x54sqb.png",
          testimony:
            "Sodales felis viverra morbi tellus eu aliquam malesuada. Egestas in diam risus at et. Pulvinar phasellus ut pharetra donec pellentesque facilisi aliquam nam nisl. Metus purus feugiat egestas ac, sit eleifend massa. Varius cursus pellentesque amet posuere egestas urna odio risus lorem. Arcu sed proin mattis cras. Sed amet, elementum lectus bibendum mi. Arcu ligula nibh nam sed convallis rhoncus morbi.",
        },
        {
          id: 4,
          name: "Ronaldo Goméz",
          rol: "Lorem Ipsum",
          key: "employee-4",
          photo:
            "https://res.cloudinary.com/arepa-tec/image/upload/v1599060327/lifters/image_18_udnrmw.png",
          testimony:
            "Sodales felis viverra morbi tellus eu aliquam malesuada. Egestas in diam risus at et. Pulvinar phasellus ut pharetra donec pellentesque facilisi aliquam nam nisl. Metus purus feugiat egestas ac, sit eleifend massa. Varius cursus pellentesque amet posuere egestas urna odio risus lorem. Arcu sed proin mattis cras. Sed amet, elementum lectus bibendum mi. Arcu ligula nibh nam sed convallis rhoncus morbi.",
        },
      ],
      title: "Business leads",
    },
    {
      id: 3,
      employees: [
        {
          id: 5,
          name: "Ronaldo Goméz",
          rol: "Lorem Ipsum",
          key: "employee-5",
          photo:
            "https://res.cloudinary.com/arepa-tec/image/upload/v1599060327/lifters/image_22_uesnzd.png",
          testimony:
            "Sodales felis viverra morbi tellus eu aliquam malesuada. Egestas in diam risus at et. Pulvinar phasellus ut pharetra donec pellentesque facilisi aliquam nam nisl. Metus purus feugiat egestas ac, sit eleifend massa. Varius cursus pellentesque amet posuere egestas urna odio risus lorem. Arcu sed proin mattis cras. Sed amet, elementum lectus bibendum mi. Arcu ligula nibh nam sed convallis rhoncus morbi.",
        },
        {
          id: 6,
          name: "Ronaldo Goméz",
          rol: "Lorem Ipsum",
          key: "employee-6",
          photo:
            "https://res.cloudinary.com/arepa-tec/image/upload/v1599060327/lifters/image_16_nonwtk.png",
          testimony:
            "Sodales felis viverra morbi tellus eu aliquam malesuada. Egestas in diam risus at et. Pulvinar phasellus ut pharetra donec pellentesque facilisi aliquam nam nisl. Metus purus feugiat egestas ac, sit eleifend massa. Varius cursus pellentesque amet posuere egestas urna odio risus lorem. Arcu sed proin mattis cras. Sed amet, elementum lectus bibendum mi. Arcu ligula nibh nam sed convallis rhoncus morbi.",
        },
      ],
      title: "Design leads",
    },
    {
      id: 4,
      employees: [
        {
          id: 7,
          name: "Ronaldo Goméz",
          rol: "Lorem Ipsum",
          key: "employee-7",
          photo:
            "https://res.cloudinary.com/arepa-tec/image/upload/v1599060327/lifters/image_16_nonwtk.png",
          testimony:
            "Sodales felis viverra morbi tellus eu aliquam malesuada. Egestas in diam risus at et. Pulvinar phasellus ut pharetra donec pellentesque facilisi aliquam nam nisl. Metus purus feugiat egestas ac, sit eleifend massa. Varius cursus pellentesque amet posuere egestas urna odio risus lorem. Arcu sed proin mattis cras. Sed amet, elementum lectus bibendum mi. Arcu ligula nibh nam sed convallis rhoncus morbi.",
        },
        {
          id: 8,
          name: "Ronaldo Goméz",
          rol: "Lorem Ipsum",
          key: "employee-8",
          photo:
            "https://res.cloudinary.com/arepa-tec/image/upload/v1599060327/lifters/image_24_rina7w.png",
          testimony:
            "Sodales felis viverra morbi tellus eu aliquam malesuada. Egestas in diam risus at et. Pulvinar phasellus ut pharetra donec pellentesque facilisi aliquam nam nisl. Metus purus feugiat egestas ac, sit eleifend massa. Varius cursus pellentesque amet posuere egestas urna odio risus lorem. Arcu sed proin mattis cras. Sed amet, elementum lectus bibendum mi. Arcu ligula nibh nam sed convallis rhoncus morbi.",
        },
      ],
      title: "Recursos leads",
    },
  ]

  const dummyCity = [
    { id: 1, name: "país" },
    { id: 2, name: "país" },
    { id: 3, name: "país" },
    { id: 4, name: "país" },
    { id: 5, name: "país" },
  ]

  const dummyBusiness = [
    { id: 1, name: "Business", background: planet1 },
    { id: 2, name: "Business", background: planet2 },
    { id: 3, name: "Business", background: planet3 },
    { id: 4, name: "Business", background: planet4 },
  ]

  const dummyCardsSearch = [
    {
      id: 1,
      title: "UX de Growth",
      date: "10/12/20",
      description:
        "Massa, nulla euismod tellus, ultricies nisl, vestibulum ut. Egestas morbi nulla convallis lectus elit blandit vitae. , vestibulum ut. Egesta.",
      value: "100.000",
      remote: "Remote",
    },
    {
      id: 2,
      title: "UX de Growth",
      date: "10/12/20",
      description:
        "Massa, nulla euismod tellus, ultricies nisl, vestibulum ut. Egestas morbi nulla convallis lectus elit blandit vitae. , vestibulum ut. Egesta.",
      value: "100.000",
      remote: "Remote",
    },
    {
      id: 3,
      title: "UX de Growth",
      date: "10/12/20",
      description:
        "Massa, nulla euismod tellus, ultricies nisl, vestibulum ut. Egestas morbi nulla convallis lectus elit blandit vitae. , vestibulum ut. Egesta.",
      value: "100.000",
      remote: "Remote",
    },
    {
      id: 4,
      title: "UX de Growth",
      date: "10/12/20",
      description:
        "Massa, nulla euismod tellus, ultricies nisl, vestibulum ut. Egestas morbi nulla convallis lectus elit blandit vitae. , vestibulum ut. Egesta.",
      value: "100.000",
      remote: "Remote",
    },
    {
      id: 5,
      title: "UX de Growth",
      date: "10/12/20",
      description:
        "Massa, nulla euismod tellus, ultricies nisl, vestibulum ut. Egestas morbi nulla convallis lectus elit blandit vitae. , vestibulum ut. Egesta.",
      value: "100.000",
      remote: "Remote",
    },
    {
      id: 6,
      title: "UX de Growth",
      date: "10/12/20",
      description:
        "Massa, nulla euismod tellus, ultricies nisl, vestibulum ut. Egestas morbi nulla convallis lectus elit blandit vitae. , vestibulum ut. Egesta.",
      value: "100.000",
      remote: "Remote",
    },
    {
      id: 7,
      title: "UX de Growth",
      date: "10/12/20",
      description:
        "Massa, nulla euismod tellus, ultricies nisl, vestibulum ut. Egestas morbi nulla convallis lectus elit blandit vitae. , vestibulum ut. Egesta.",
      value: "100.000",
      remote: "Remote",
    },
    {
      id: 8,
      title: "UX de Growth",
      date: "10/12/20",
      description:
        "Massa, nulla euismod tellus, ultricies nisl, vestibulum ut. Egestas morbi nulla convallis lectus elit blandit vitae. , vestibulum ut. Egesta.",
      value: "100.000",
      remote: "Remote",
    },
  ]

  const {
    seo,
    section_about_us,
    section_objective,
    section_indicators,
    data
  } = contentfulPageJobs

  return (
    <Layout fix="layout-fix">
      <SEO {...seo} lang={languageISO639} />
      <div className="jobs">
        <SectionBanner
          dummyCity={dummyCity}
          dummyBusiness={dummyBusiness}
          dummyCardsSearch={dummyCardsSearch}
          sectionInitial="banner"
          subTitle="Vacantes"
          text_2="¿Tienes una ‘actitud de otro planeta’? Eres talentoso/a, buscas aprender y crecer constantemente, ¡Te estamos buscando! Ven y forma parte del equipo mundial de aliens que está revolucionando la logística y moviendo a Latinoamérica."
        />
        <SectionAboutUs
          title={section_about_us.title}
          text={section_about_us.description?.description}
          video={section_about_us.image}
          link={section_about_us.link}
        />
        <SectionObjectives
          title={section_objective.title}
          text={section_objective.description?.description}
          comment={section_objective.comment}
          subtitle={section_indicators.title}
          data={data}
        />
        <SectionMedia
          title="¡Creciendo con los mejores!"
          company={dummyCompany}
          subTitle="Liftit en los medios"
          caroucel={dummyCaroucelMedia}
        />
        <div className="finish">
          <SectionFounders
            title="Founders"
            dummyEmployees={dummyEmployees}
            dummyFounders={dummyFounders}
          />
          <SectionBanner
            dummyCity={dummyCity}
            dummyBusiness={dummyBusiness}
            dummyCardsSearch={dummyCardsSearch}
            sectionInitial="footer"
            subTitle="Vacantes"
            text_2="¿Tienes una ‘actitud de otro planeta’? Eres talentoso/a, buscas aprender y crecer constantemente, ¡Te estamos buscando! Ven y forma parte del equipo mundial de aliens que está revolucionando la logística y moviendo a Latinoamérica."
          />
        </div>
      </div>
    </Layout>
  )
}


export const pageQuery = graphql`
  query PageJobs($locale: String) {
    contentfulPaginaJobs(language: { language: { eq: $locale } }) {
      seo {
        ...SEOFragment
      }
      section_banner {
        ...SectionFragment
      }
      numbers_banner {
        ...LinkFragment
      }
      section_about_us {
        ...SectionFragment
      }
      section_objective {
        ...SectionFragment
      }
      section_indicators {
        ...SectionFragment
      }
      data {
        internal {
          content
        }
      }
      section_5_liftit_media {
        ...SectionFragment
      }
      founders {
        ...TeamFragment
      }
      section_7_why_work_liftit {
        ...SectionFragment
      }
      vacancies {
        ...SectionFragment
      }
    }
  }
`

export default Jobs
