import React, {useState} from "react";
import { CardVacancies } from "../../SectionWhyWorkAtLiftit/components/CardVacancies/CardVacancies";
import { Form, Input, Button } from "antd"

export const Vacancies = ({dummyCity, dummyBusiness, handlerSetSectionInternal}) => {

	const [cityActive, setCityActive] = useState(1)

	const onFinish = values => {
    console.log("Success:", values)
  }
	
	const handleVacancies = (values) => {
		handlerSetSectionInternal(values)
	}

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo)
	}

	const handleCity = id => {
    setCityActive(id)
	}
	
	return (
		<div className="vacancies--view">
			<div
				className="vacancies animate__fadeIn animate__animated"
				data-aos="fade-up"
			>
				<CardVacancies subTitle={"Vacantes"} text_2={"¿Tienes una ‘actitud de otro planeta’? Eres talentoso/a, buscas aprender y crecer constantemente, ¡Te estamos buscando! Ven y forma parte del equipo mundial de aliens que está revolucionando la logística y moviendo a Latinoamérica. "} />
				<Form
					className="search"
					name="basic"
					initialValues={{ remember: true }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					<Form.Item
						className="form-item"
						name="seacrh"
						rules={[
							{ required: true, message: "Please input your username!" },
						]}
					>
						<Input />
					</Form.Item>

					<Form.Item className="cont-btn">
						<Button className="btn btn--primary" htmlType="submit">
							Buscar
                  </Button>
					</Form.Item>
				</Form>
				<div className="cities">
					{dummyCity?.map((item, index) => (
						<div
							key={index}
							onClick={() => handleCity(item.id)}
							className={`cities__city ${cityActive === item.id && "city-active"
								}`}
						>
							<div className="circle" />
							<h4>{item.name}</h4>
						</div>
					))}
				</div>
				<div className="cards">
					{dummyBusiness?.map((item, index) => (
						<div key={index} className="cards--card">
							<div className="circle" style={{ backgroundImage: `url(${item.background})` }} />
							<p>{item.name}</p>
							<div className="cont-btn">
								<Button className="btn btn--primary">Ver vacantes</Button>
							</div>
						</div>
					))}
				</div>
				<div className="cont-btn see-all">
					<Button
						className="btn btn--primary"
						onClick={() => handleVacancies("VacancyList")}
					>
						Ver todo
          </Button>
				</div>
			</div>
		</div>
	);
}