import React from "react"

export const CardVacancies = ({ subTitle, text_2 }) => {
  return (
    <div className="card_vac">
      <h1>{subTitle}</h1>
      <p>{text_2}</p>
    </div>
  )
}
