import React, { useState } from "react"
import Slider from "react-slick"

import alien1 from "../../../assets/images/alienf.png"
import alien2 from "../../../assets/images/aliene.png"

const settings = {
  focusOnSelect: true,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  speed: 10,
}

export const SectionFounders = ({ title, dummyEmployees, dummyFounders }) => {
  const [active, setActive] = useState(1)
  const [itemActive, setItemActive] = useState("")

  const handleProfile = id => {
    setActive(id)
  }

  const handleItem = key => {
    if (itemActive === key) setItemActive("")
    else setItemActive(key)
  }

  return (
    <div className="SectionFounders" data-aos="fade-up">
      <div className="SectionFounders__container">
        <div className="SectionFounders__container__card">
          <div className="SectionFounders__container__card--left">
            <h1>{title}</h1>
            <div className="caroucel">
              <Slider {...settings}>
                {dummyFounders?.map((item, index) => (
                  <div
                    key={index}
                    className="card-fun"
                    onClick={() => handleProfile(index)}
                  >
                    <div>
                      <h6 className="name">{item.name}</h6>
                    </div>
                    <img
                      className="img animate__fadeIn animate__animated"
                      src={item.image}
                      alt="liftersFounders"
                    />
                  </div>
                ))}
              </Slider>
            </div>
            {dummyFounders?.map((item, index) => (
              <div className="profile" key={index}>
                <div className="profile--item">
                  {index === active && (
                    <div>
                      <div className="cont-name">
                        <img src={alien2} className="alien" alt="alien" />
                        <h5>
                          {item.name}, {item.rol}
                        </h5>
                      </div>
                      <p>{item.description}</p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="SectionFounders__container__card--right">
            <div className="title">
              <h1>Equipo LATAM</h1>
              <img src={alien1} alt="alien" className="alien" />
            </div>
            <div className="content">
              <div>
                <div className="profiles">
                  {dummyEmployees?.map((item, index) => (
                    <div key={index} className="content-cards">
                      <h2>{item.title}</h2>
                      <div className="items">
                        {item.employees?.map((data, i) => (
                          <div
                            key={i}
                            className={`items--item ${
                              data.key === itemActive && "items--item--active"
                            }`}
                            onClick={() => handleItem(data.key)}
                          >
                            <img
                              src={data.photo}
                              alt="avatar"
                              className="img-avatar"
                            />
                            <h4>{data.name}</h4>
                            <p className="text">{data.testimony}</p>
                            <span>{data.rol}</span>
                            <div className="circles">
                              <div className="circles__circle" />
                              <div className="circles__circle" />
                              <div className="circles__circle" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
