import React from "react"
import { Form, Input, Button } from "antd"
import { CloseOutlined, SearchOutlined, MenuOutlined } from "@ant-design/icons"

import { CardVacancies } from "../CardVacancies/CardVacancies"
import { CardInformation } from "../CardInformation/CardInformation"

export const VacancyList = ({ subTitle, text_2, dummyCardsSearch }) => {
  const onFinish = values => {
    /* eslint-disable no-console */
    console.log("Success:", values)
    /* eslint-enable no-console */
  }

  const onFinishFailed = error => {
    /* eslint-disable no-console */
    console.log("Failed:", error)
    /* eslint-enable no-console */
  }

  return (
    <div className="VacancyList">
      <div className="VacancyList--cont">
        <CardVacancies subTitle={subTitle} text_2={text_2} />
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="form--search"
        >
          <Form.Item
            className="form-item"
            name="search"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item className="cont-btn">
            <Button className="btn btn--primary" htmlType="submit">
              Ver todo
            </Button>
          </Form.Item>
          <Form.Item className="cont-btn">
            <Button className="btn btn--filter">Filtrar</Button>
          </Form.Item>
        </Form>
        <div className="search-icons">
          <SearchOutlined />
          <MenuOutlined />
        </div>
        <div className="cities">
          <div className="cities__city">
            <div className="circle" />
            <h4>país</h4>
            <CloseOutlined />
          </div>
          <div className="cont-btn">
            <Button className="btn btn--labels">
              Etiquetas
              <CloseOutlined />
            </Button>
          </div>
        </div>
        <CardInformation dummyCardsSearch={dummyCardsSearch} />
      </div>
    </div>
  )
}
