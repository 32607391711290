import React, { useState, useEffect } from "react"
import { useInView } from "react-intersection-observer"

import { Count } from "./components/Count"
import logo from "../../../assets/images/logoLif.png"

export const SectionObjectives = ({
  title,
  text,
  comment,
  subtitle,
  data
}) => {
  const [animatedSection, inAnimatedSection] = useInView({ threshold: 0 })
  const [dataIndicators, setDataIndicators] = useState()

  useEffect(() => {
    setDataIndicators(JSON.parse(data.internal.content))
  }, [])

  return (
    <div className="objectives">
      <div className="objectives__container">
        <div className="objectives__container--box-left">
          <h1>{title}</h1>
          <p>{text}</p>
          {comment &&
            <div className="card">
              <img src={comment.photo} alt="biographyAvatar" className="avatar" />
              <span>{comment.comment?.comment}</span>
            </div>
          }
        </div>
        {dataIndicators &&
          <div className="objectives__container--box-right">
            <div className="card-est" data-aos="fade-up">
              <h2>{subtitle}</h2>
              <div className="cont-statistics">
                <div className="svgs">
                  <div className="sect">
                    <div className="span span--top span--left-top">
                      <span className="num">
                        {dataIndicators.lifters.digitStart}
                        <Count number={dataIndicators.lifters.number} />
                        {' '}{dataIndicators.lifters.label}
                      </span>
                    </div>
                    <svg
                      className={`svg--left-top ${
                        inAnimatedSection && "animated-line"
                        }`}
                      width="100"
                      height="23"
                      viewBox="0 0 120 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M119.068 1.47461H43.0922L0.720947 21.4285"
                        stroke="#334AE6"
                        strokeWidth="2"
                      />
                    </svg>
                  </div>
                  <div className="sect">
                    <div className="span span--down span--left-bottom">
                      <span className="num">
                        {dataIndicators.happyClients.digitStart}
                        <Count number={dataIndicators.happyClients.number} />
                        {' '}{dataIndicators.happyClients.label}
                      </span>
                    </div>
                    <svg
                      className={`svg--left-bottom ${
                        inAnimatedSection && "animated-line"
                        }`}
                      width="100"
                      height="22"
                      viewBox="0 0 120 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M119.068 20.8613H43.0922L0.720947 0.907459"
                        stroke="#334AE6"
                        strokeWidth="2"
                      />
                    </svg>
                  </div>
                </div>
                <div className="statistics">
                  <img className="logo" src={logo} alt="logoLifti" />
                </div>
                <div className="svgs">
                  <div className="sect">
                    <svg
                      className={`svg-rotate svg--rigth-top ${
                        inAnimatedSection && "animated-line"
                        }`}
                      width="100"
                      height="22"
                      viewBox="0 0 120 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M119.068 20.8613H43.0922L0.720947 0.907459"
                        stroke="#334AE6"
                        strokeWidth="2"
                      />
                    </svg>
                    <div className="span span--top span--rigth-top">
                      <span className="num">
                        {dataIndicators.nps.digitStart}
                        {dataIndicators.nps.label}{' '}
                        <Count number={dataIndicators.nps.number} />
                      </span>
                    </div>
                  </div>
                  <div className="sect">
                    <svg
                      className={`svg-rotate svg--rigth-bottom ${
                        inAnimatedSection && "animated-line"
                        }`}
                      width="100"
                      height="23"
                      viewBox="0 0 120 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M119.068 1.47461H43.0922L0.720947 21.4285"
                        stroke="#334AE6"
                        strokeWidth="2"
                      />
                    </svg>
                    <div className="span span--down span--rigth-bottom">
                      <span className="num">
                        {dataIndicators.otif.digitStart}
                        {dataIndicators.otif.label}{' '}
                        <Count number={dataIndicators.otif.number} />
                        {dataIndicators.otif.digitEnd}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="count" ref={animatedSection}>
                <div className="count--cont">
                  <div className="count--cont--title">{dataIndicators.deliveries.label}</div>
                  <div className="count--cont--title">{dataIndicators.investment.label}</div>
                  <div className="count--cont--title">{dataIndicators.collaborators.label}</div>
                </div>
                <div className="count__card">
                  <span>
                    {dataIndicators.deliveries.digitStart}
                    <Count number={dataIndicators.deliveries.number} />
                    {dataIndicators.deliveries.digitEnd}
                  </span>
                  <span>
                    {dataIndicators.investment.digitStart}
                    <Count number={dataIndicators.investment.number} />
                    {dataIndicators.investment.digitEnd}
                  </span>
                  <span>
                    {dataIndicators.collaborators.digitStart}
                    <Count number={dataIndicators.collaborators.number} />
                    {dataIndicators.collaborators.digitEnd}
                  </span>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
